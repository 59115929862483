<template>
    <div>
        <CBadge v-if="statusColor(row.item.entity_status_id)"
                :style="'background-color:'+statusColor(row.item.entity_status_id)">{{ row.item.entity_status }}
        </CBadge>
        <CBadge v-else :color="statusClass(row.item.entity_status)">{{ row.item.entity_status }}</CBadge>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        'row': [Object],
    },
    methods: {
        ...mapGetters('CommonData', ['getEntityStatusColors']),
        statusColor(entity_status_id) {
            if (Object.hasOwn(this.getEntityStatusColors(), entity_status_id)) {
                return this.getEntityStatusColors()[entity_status_id];
            } else {
                return false;
            }
        },
        statusClass(status) {
            return 'light';
        },
    }
}
</script>